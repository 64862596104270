import script from "./CustomDrawer.vue?vue&type=script&setup=true&lang=js"
export * from "./CustomDrawer.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QList,QExpansionItem});
